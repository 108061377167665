export const dropdownMap = (val) => {
  const { id_grup_aset, kode_grup_aset, kode_kategori_aset, kode_item_aset } =
    val;

  return {
    id_grup_aset,
    kode_grup_aset,
    kode_kategori_aset,
    kode_item_aset,

    value:
      val.id_grup_aset ??
      val.id_kategori_aset ??
      val.id_jenis_aset ??
      val.id_item_aset,

    label:
      val.nama_grup_aset ??
      val.nama_kategori_aset ??
      val.nama_jenis_aset ??
      val.nama_item_aset,
  };
};
