import { Services, UploadServices } from '../../../../services';

class AsetLainApi {
  getPage(page, dataLength, key) {
    return Services.get(
      `/asetlainnya/page/?page=${page}&per_page=${dataLength}&q=${key}`
    );
  }

  getKode() {
    return Services.get('/asetlainnya/no_baru?id_kategori_aset=20');
  }

  getKategoriAset() {
    return Services.get('/asetlainnya/kategori');
  }

  getKaryawan() {
    return Services.get('/karyawan/dropdown');
  }

  getUnitOrganisasi() {
    return Services.get('/unit_organisasi/dropdown');
  }

  getProvinsi() {
    return Services.get('/wilayah/dropdown_provinsi');
  }

  getKabupaten(id) {
    return Services.get('/wilayah/dropdown_kabupaten?id_provinsi=' + id);
  }

  getKecamatan(id) {
    return Services.get('/wilayah/dropdown_kecamatan?id_kabupaten=' + id);
  }

  getDesa(id) {
    return Services.get('/wilayah/dropdown_desa?id_kecamatan=' + id);
  }

  getSingle(id_item_aset) {
    return Services.get('/asetlainnya/single/?id_item_aset=' + id_item_aset);
  }

  create(data) {
    return Services.post('/asetlainnya', data);
  }

  update(data) {
    return Services.put('/asetlainnya', data);
  }

  delete(id) {
    return Services.post('/asetlainnya/delete', id);
  }

  show(id) {
    return Services.put('/asetlainnya/show', id);
  }

  hide(id) {
    return Services.put('/asetlainnya/hide', id);
  }

  getKondisi() {
    return Services.get('/asetlainnya/dropdown_kondisi_aset');
  }

  uploadFileMultiple(data) {
    return UploadServices.post('/upload/aset_multiple', data);
  }

  uploadFile(data) {
    return Services.post('/asetlainnya/gambar', data);
  }

  deleteFile(id) {
    return Services.post('/asetlainnya/gambar_delete', id);
  }

  dropdown(value) {
    return Services.get('/asetlainnya/dropdown', { params: { ...value } });
  }

  single(value) {
    return Services.get('/asetlainnya/single', { params: { ...value } });
  }

  no_baru(value) {
    return Services.get('/asetlainnya/no_baru', { params: { ...value } });
  }

  singleCheck(value) {
    return Services.get('/asetlainnya/single_check', { params: { ...value } });
  }
  export() {
    return Services.get('/asetlainnya/xlsx');
  }
}

export default new AsetLainApi();
