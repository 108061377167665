import { Fragment, useContext, useEffect, useState } from 'react';
import { Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import {
  ActionButton,
  Alert,
  BackButton,
  CRUDLayout,
  DataStatus,
} from 'components';
import { TransferProduksiAlatMesinApi } from 'api';
import {
  InfoDetailJobOrder,
  ModalFormTransfer,
  TabMachineProcess,
  ModalKonfirmasi,
} from './Components';
import {
  TransferAlatMesinContext,
  withDropdownTransferAlatProvider,
  withTransferAlatMesinProvider,
} from './Context';
import { useMockNumber } from './Hooks';
import { DateConvert } from 'utilities';

const NavTab = ({ eventKey, title }) => (
  <Nav.Item>
    <Nav.Link eventKey={eventKey}>{title}</Nav.Link>
  </Nav.Item>
);

const TabPane = ({ eventKey, component }) => (
  <Tab.Pane eventKey={eventKey}>{component}</Tab.Pane>
);

const TambahTransferAlatMesin = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const { id_permintaan_produksi } = useParams();
  const [generateMockNumber] = useMockNumber();
  const {
    dataJobOrder,
    setJobOrder,
    dataPermintaan,
    dataTransfer,
    setDataPermintaan,
    setDataHistory,
    modalForm,
    setModalForm,
    setModalKonfirmasi,
  } = useContext(TransferAlatMesinContext);

  const [isPageLoading, setPageLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [alert, setAlert] = useState({
    variant: 'primary',
    text: '',
  });

  const onSubmit = () => {
    setSubmitting(true);

    const dataStatusPermintaan = dataPermintaan?.map((item) => ({
      id_permintaan_produksi_mc: item.id_permintaan_produksi_mc,
      status_permintaan: item.status_permintaan,
    }));

    const listTransfer = {
      data: dataTransfer?.map((item) => ({
        ...item,
        ref_alat_mesin: 'amp',
        tgl_transfer_produksi_alat_mesin: DateConvert(
          item.tgl_transfer_produksi_alat_mesin
        ).default,
      })),
    };

    axios
      .all([
        TransferProduksiAlatMesinApi.updateStatusPermintaan(
          dataStatusPermintaan
        ),
        TransferProduksiAlatMesinApi.create(listTransfer),
      ])
      .then(() =>
        history.push('/transaksi/transfer-produksi-alat-mesin', {
          alert: {
            variant: 'primary',
            text: 'Tambah Data Transfer Alat Mesin Produksi Berhasil',
          },
        })
      )
      .catch(({ response }) =>
        setAlert({
          variant: 'danger',
          text: `Gagal Menyimpan Data Transfer Alat Mesin Produksi ${
            response?.data?.message ?? ''
          }`,
        })
      )
      .finally(() => {
        setSubmitting(false);
      });
  };

  const getInitialData = () => {
    setPageLoading(true);

    TransferProduksiAlatMesinApi.single({ id_permintaan_produksi })
      .then((res) => {
        const { alat_mesin, history, ...jobOrder } = res?.data?.data;

        setJobOrder(jobOrder ?? {});
        setDataPermintaan(alat_mesin ?? []);
        setDataHistory(history ?? []);
      })
      .catch(() => setAlert({ text: 'Data gagal dimuat', variant: 'danger' }))
      .finally(() => setPageLoading(false));
  };

  const checkAlert = () => {
    const alertState = location?.state?.alert;
    if (alertState) {
      setAlert({ text: alertState.text, variant: alertState.variant });
    }
  };

  useEffect(() => {
    setNavbarTitle('Transfer Alat Mesin Produksi');
    getInitialData();
    checkAlert();
  }, []);

  return (
    <CRUDLayout>
      <Row className="d-flex align-items-end justify-content-between mb-2">
        <Col>
          <div>Detail Data Job Order </div>
        </Col>
        <Col className="d-flex justify-content-end align-items-end">
          <BackButton onClick={() => history.goBack()} />
        </Col>
      </Row>

      <Alert
        showCloseButton
        className="mt-2"
        show={!!alert?.text?.length}
        variant={alert.variant}
        text={alert.text}
        onClose={() => setAlert((prev) => ({ ...prev, text: '' }))}
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <Fragment>
          <InfoDetailJobOrder data={dataJobOrder} />

          <Card className="mt-4">
            <Tab.Container defaultActiveKey="mp">
              {/* Tab Navigation */}
              <Card.Header>
                <Nav variant="tabs" defaultActiveKey="mp">
                  <NavTab eventKey="mp" title="Machine Process" />
                </Nav>
              </Card.Header>

              {/* Tab Content */}
              <Card.Body>
                <Tab.Content>
                  <TabPane eventKey="mp" component={<TabMachineProcess />} />
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
          <Row className="d-flex justify-content-end align-items-center px-3 pt-4 pb-2 ">
            <ActionButton
              disable={isSubmitting}
              text="Simpan"
              loading={isSubmitting}
              onClick={() => setModalKonfirmasi(true)}
            />
          </Row>
        </Fragment>
      )}

      {modalForm.show && (
        <ModalFormTransfer
          generateMockNumber={generateMockNumber}
          modal={modalForm}
          closeModal={() =>
            setModalForm({
              show: false,
              data: null,
              type: '',
            })
          }
        />
      )}

      <ModalKonfirmasi handleSubmit={onSubmit} isSubmitting={isSubmitting} />
    </CRUDLayout>
  );
};

export default withDropdownTransferAlatProvider(
  withTransferAlatMesinProvider(TambahTransferAlatMesin)
);
