import { Fragment, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { TablePermintaanMP } from './TablePermintaanMP';
import { TableTransferMP } from './TableTransferMP';
import { TransferAlatMesinContext } from '../Context';

export const TabMachineProcess = () => {
  const { dataPermintaan, dataTransfer, dataHistory } = useContext(
    TransferAlatMesinContext
  );

  return (
    <Fragment>
      <Row className="d-flex justify-content-between align-items-end mb-1">
        <Col>
          <small>List Data Permintaan Machine Process</small>
        </Col>
      </Row>
      <TablePermintaanMP data={dataPermintaan ?? []} />

      <small className="mt-2">List Data Transfer Machine Process</small>
      <TableTransferMP
        tipe="editable"
        tipeTransfer="penunjang"
        data={dataTransfer ?? []}
        uniqueKey="id_permintaan_produksi_finishing"
      />

      <small className="mt-2">History Transfer Machine Process</small>
      <TableTransferMP tipe="readonly" data={dataHistory} />
    </Fragment>
  );
};
