import { useEffect, useState } from 'react';
import { Card, Col, Modal, Row } from 'react-bootstrap';
import { IoTrashBinOutline } from 'react-icons/io5';
import { useHistory, useLocation } from 'react-router-dom';
import Axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  ActionButton,
  Alert,
  BackButton,
  DataStatus,
  DeleteButton,
  Input,
  SelectSearch,
  TextArea,
} from 'components';
import { TransferProduksiAlatMesinApi } from 'api';
import { DateConvert } from 'utilities';

const DataTransferAlatMesin = ({ setNavbarTitle }) => {
  const title = 'Transfer Produksi Alat Mesin';
  const history = useHistory();
  const {
    id_barang_jadi = 9,
    id_job_order = 34478,
    ref_uid,
  } = useLocation()?.state ?? {};

  const [isLoading, setIsLoading] = useState(false);
  const [dataModal, setDataModal] = useState({
    show: false,
  });
  const [isDeleteData, setIsDeleteData] = useState(false);
  const [dataForm, setDataForm] = useState({
    id_permintaan_produksi: '',
    tgl_transfer_produksi_alat_mesin: '',
    id_item_buaso: '',
    nama_item_buaso: '',
    qty_transfer_produksi_alat_mesin: '',
    ref_alat_mesin: '',
  });
  const [data, setData] = useState({
    analisa_POP: [],
  });
  const [dataHistory, setDataHistory] = useState({
    history_POP: [],
  });
  const [dataKaryawan, setDataKaryawan] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
    status: false,
  });

  const getInitialData = () => {
    setIsLoading(true);

    Axios.all([
      TransferProduksiAlatMesinApi.single({
        id_job_order: id_job_order,
        id_barang_jadi: id_barang_jadi,
        ref_uid: ref_uid,
      }),
      TransferProduksiAlatMesinApi.dropdown({ tipe: 'karyawan' }),
    ])
      .then(
        Axios.spread((res, karyawan) => {
          const data = res.data.data;
          setData({ permintaan_PMC: [{}] });
          setDataKaryawan(karyawan.data.data);
          TransferProduksiAlatMesinApi.transfer({
            id_permintaan_produksi:
              data.id_permintaan_produksi.PMC != null
                ? data.id_permintaan_produksi.PMC
                : 0,
            ref_alat_mesin: 'amp',
          })
            .then((pop) => {
              setDataHistory({
                history_POP: pop.data.data,
              });
            })
            .finally(() => setIsLoading(false));
        })
      )
      .catch(() => {
        setAlertConfig({
          variant: 'danger',
          text: 'Data gagal dimuat',
        });
        setShowAlert(true);
      })
      .finally(() => setIsLoading(false));
  };

  const getNoBaru = (value, setValues, values) => {
    TransferProduksiAlatMesinApi.no_baru({
      tanggal: value,
    }).then((res) =>
      setValues({
        ...values,
        tgl_transfer_produksi_alat_mesin: value,
        no_transfer_alat_mesin: res.data.data,
      })
    );
  };

  useEffect(() => {
    setNavbarTitle(title);
    getInitialData();
  }, [setNavbarTitle]);

  const formInitialValues = {
    id_permintaan_produksi: dataForm?.id_permintaan_produksi?.PMC,
    tgl_transfer_produksi_alat_mesin: dataForm.tgl_transfer_produksi_alat_mesin,
    no_transfer_alat_mesin: '',
    id_item_buaso: dataForm.id_item_buaso,
    nama_item_buaso: dataForm.nama_item_buaso,
    qty_transfer_produksi_alat_mesin: dataForm.qty_transfer_produksi_alat_mesin,
    id_karyawan: dataForm.id_karyawan ?? '',
    keterangan: dataForm.keterangan ?? '',
    ref_alat_mesin: dataForm.ref_alat_mesin,
  };

  const formValidationSchema = Yup.object().shape({
    tgl_transfer_produksi_alat_mesin: Yup.date()
      .typeError('Inputan salah')
      .required('Tanggal transfer alat mesin wajib diisi'),
  });

  const formSubmitHandler = (values, { setSubmitting }) => {
    TransferProduksiAlatMesinApi.create({
      id_permintaan_produksi: values.id_permintaan_produksi,
      tgl_transfer_produksi_alat_mesin: values.tgl_transfer_produksi_alat_mesin,
      id_item_aset: values.id_item_buaso,
      qty_transfer_produksi_alat_mesin: values.qty_transfer_produksi_alat_mesin,
      id_karyawan: values.id_karyawan,
      keterangan: values.keterangan,
      ref_alat_mesin: values.ref_alat_mesin,
    })
      .then(() => {
        setAlertConfig({
          variant: 'primary',
          text: 'Tambah data berhasil!',
        });
      })
      .catch(() => {
        setAlertConfig({
          variant: 'danger',
          text: `Tambah data gagal!`,
        });
      })
      .finally(() => {
        setDataModal({
          ...dataModal,
          show: false,
        });
        setSubmitting(false);
        setShowAlert(true);
        getInitialData();
      });
  };

  const HapusModal = () => {
    const deleteValue = {
      id_transfer_produksi_alat_mesin: dataForm.id_transfer_produksi_alat_mesin,
    };
    const [btnLoading, setBtnLoading] = useState(false);
    const deleteDataHandler = () => {
      setBtnLoading(true);
      TransferProduksiAlatMesinApi.delete(deleteValue)
        .then((res) => {
          setAlertConfig({
            variant: 'primary',
            text: 'Hapus data berhasil!',
          });
        })
        .catch((err) => {
          setAlertConfig({
            variant: 'danger',
            text: `Hapus data gagal! (${err.response.data.message})`,
          });
        })
        .finally(() => {
          setIsDeleteData(false);
          setShowAlert(true);
          getInitialData();
        });
    };

    return (
      <Modal show={isDeleteData} onHide={() => setIsDeleteData(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">
            <h6 className="mb-0">
              <IoTrashBinOutline className="mb-1 mr-2" size={20} />
              Hapus Data {title}
            </h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5 className="mb-1">Apakah anda yakin menghapus data?</h5>
          <div>
            <div>
              Nomor Kontrak : {dataForm.no_transfer_produksi_alat_mesin}
            </div>
          </div>
          <small className="text-danger">
            Data yang telah dihapus tidak dapat dikembalikan
          </small>
          <div className="mt-3">
            <ActionButton
              variant="outline-secondary"
              text="Batal"
              onClick={() => setIsDeleteData(false)}
            />
            <ActionButton
              variant="danger"
              text="Hapus data"
              onClick={deleteDataHandler}
              loading={btnLoading}
              className="ml-1"
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const TambahData = () => {
    const ShowData = ({ title, text }) => (
      <div className="d-flex flex-row">
        <div className="text-secondary mr-3" style={{ width: 120 }}>
          <small style={{ textTransform: 'capitalize' }}>{title}</small>
        </div>
        <small>:</small>
        <small
          className="text-dark"
          style={{
            fontWeight: 200,
            paddingLeft: 10,
          }}
        >
          {text ? text : '-'}
        </small>
      </div>
    );
    return (
      <>
        {data ? (
          <>
            <Row>
              <Col lg="6">
                <ShowData title="No. RAP" text={data.no_rap} />

                <ShowData title="Customer" text={data.nama_customer} />
                <ShowData
                  title="No. Permintaan"
                  text={data.no_permintaan_produksi}
                />
              </Col>

              <Col lg="6">
                <ShowData title="No. JO" text={data.no_job_order} />

                <ShowData title="Barang Jadi" text={data.nama_barang_jadi} />

                <ShowData
                  title="Tgl. Permintaan"
                  text={
                    DateConvert(new Date(data.tgl_permintaan_produksi)).detail
                  }
                />
              </Col>
            </Row>
            <br />
            <small>
              <b>List Transfer Alat Mesin</b>
            </small>
            <table className="table table-bordered bg-white table-sm">
              <thead className="text-center">
                <tr>
                  <th
                    className="text-center align-middle text-capitalize"
                    style={{
                      fontSize: '12px',
                      width: '12px',
                    }}
                  >
                    No
                  </th>
                  <th
                    className="text-center align-middle text-capitalize"
                    style={{
                      fontSize: '12px',
                      width: '12px',
                    }}
                  >
                    Aksi
                  </th>
                  <th
                    className="text-center align-middle text-capitalize"
                    style={{
                      fontSize: '12px',
                    }}
                  >
                    Item Alat Mesin
                  </th>
                  <th
                    className="text-center align-middle text-capitalize"
                    style={{
                      fontSize: '12px',
                    }}
                  >
                    Qty Permintaan
                  </th>
                  <th
                    className="text-center align-middle text-capitalize"
                    style={{
                      fontSize: '12px',
                    }}
                  >
                    Nama Satuan
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.permintaan_PMC &&
                  data?.permintaan_PMC?.length > 0 &&
                  data.permintaan_PMC.map((val, index) => (
                    <tr key={index}>
                      <td
                        className="p-1 px-2 align-middle"
                        style={{ fontSize: '12px' }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="p-1 px-2 align-middle text-center"
                        style={{ fontSize: '12px' }}
                      >
                        <ActionButton
                          size="sm"
                          variant="primary"
                          text="Transfer"
                          onClick={() => {
                            setDataModal({
                              show: true,
                            });

                            setDataForm({
                              id_permintaan_produksi:
                                data.id_permintaan_produksi,
                              tgl_transfer_produksi_alat_mesin: '',
                              no_transfer_alat_mesin: '',
                              id_item_buaso: val.id_item_buaso,
                              nama_item_buaso: val.nama_item,
                              qty_transfer_produksi_alat_mesin: val.qty,
                              ref_alat_mesin: 'amp',
                            });
                          }}
                        />
                      </td>
                      <td
                        className="p-1 px-2 align-middle"
                        style={{ fontSize: '12px' }}
                      >
                        {val.nama_item}
                      </td>
                      <td
                        className="p-1 px-2 align-middle"
                        style={{ fontSize: '12px' }}
                      >
                        {val.qty ? parseFloat(val.qty) : 0}
                      </td>
                      <td
                        className="p-1 px-2 align-middle"
                        style={{ fontSize: '12px' }}
                      >
                        {val.nama_satuan ? val.nama_satuan : '-'}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>

            <small>
              <b>History Transfer Alat Mesin</b>
            </small>
            <table className="table table-bordered bg-white table-sm">
              <thead className="text-center">
                <tr>
                  <th
                    className="text-center align-middle text-capitalize"
                    style={{
                      fontSize: '12px',
                      width: '12px',
                    }}
                  >
                    No
                  </th>
                  <th
                    className="text-center align-middle text-capitalize"
                    style={{
                      fontSize: '12px',
                      width: '12px',
                    }}
                  >
                    Aksi
                  </th>
                  <th
                    className="text-center align-middle text-capitalize"
                    style={{
                      fontSize: '12px',
                    }}
                  >
                    Tanggal
                  </th>
                  <th
                    className="text-center align-middle text-capitalize"
                    style={{
                      fontSize: '12px',
                    }}
                  >
                    No Kontrak
                  </th>
                  <th
                    className="text-center align-middle text-capitalize"
                    style={{
                      fontSize: '12px',
                    }}
                  >
                    Item Alat Mesin
                  </th>
                  <th
                    className="text-center align-middle text-capitalize"
                    style={{
                      fontSize: '12px',
                    }}
                  >
                    Diserahkan
                  </th>
                  <th
                    className="text-center align-middle text-capitalize"
                    style={{
                      fontSize: '12px',
                    }}
                  >
                    Qty Transfer
                  </th>
                  <th
                    className="text-center align-middle text-capitalize"
                    style={{
                      fontSize: '12px',
                    }}
                  >
                    Keterangan
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataHistory?.history_POP?.length > 0 ? (
                  dataHistory.history_POP.map((val, index) => (
                    <tr key={index}>
                      <td
                        className="p-1 px-2 align-middle"
                        style={{ fontSize: '12px' }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="p-1 px-2 align-middle"
                        style={{ fontSize: '12px' }}
                      >
                        <DeleteButton
                          size="sm"
                          onClick={() => {
                            setDataForm(val);
                            setIsDeleteData(true);
                          }}
                        />
                      </td>
                      <td
                        className="p-1 px-2 align-middle"
                        style={{ fontSize: '12px' }}
                      >
                        {
                          DateConvert(
                            new Date(val.tgl_transfer_produksi_alat_mesin)
                          ).detail
                        }
                      </td>
                      <td
                        className="p-1 px-2 align-middle"
                        style={{ fontSize: '12px' }}
                      >
                        {val.no_transfer_produksi_alat_mesin}
                      </td>
                      <td
                        className="p-1 px-2 align-middle"
                        style={{ fontSize: '12px' }}
                      >
                        {val.nama_item_aset}
                      </td>
                      <td
                        className="p-1 px-2 align-middle"
                        style={{ fontSize: '12px' }}
                      >
                        {val.nama_karyawan ?? '-'}
                      </td>
                      <td
                        className="p-1 px-2 align-middle"
                        style={{ fontSize: '12px' }}
                      >
                        {val.qty_transfer_produksi_alat_mesin
                          ? parseFloat(val.qty_transfer_produksi_alat_mesin)
                          : 0}
                      </td>
                      <td
                        className="p-1 px-2 align-middle"
                        style={{ fontSize: '12px' }}
                      >
                        {val.keterangan ?? '-'}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="6"
                      className="p-1 px-2 align-middle text-center"
                      style={{ fontSize: '12px' }}
                    >
                      <DataStatus text="Tidak ada data" />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <hr />
          </>
        ) : (
          <DataStatus text="Tidak ada data" />
        )}
      </>
    );
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex flex-row justify-content-between align-items-center">
          <b>{title}</b>
          <BackButton
            onClick={() => {
              history.goBack();
            }}
          />
        </Card.Header>
        <Card.Body>
          <Alert
            show={showAlert}
            showCloseButton={true}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() => setShowAlert(false)}
          />
          {isLoading === true ? (
            <DataStatus loading={true} text="Memuat data..." />
          ) : data !== undefined ? (
            <TambahData />
          ) : (
            <DataStatus text="Tidak ada data" />
          )}
        </Card.Body>
      </Card>

      <Modal
        show={dataModal.show}
        onHide={() =>
          setDataModal({
            show: false,
            qty: '',
          })
        }
        size="lg"
      >
        <Modal.Header closeButton>
          <div className="modal-title h5">Kontrak</div>
        </Modal.Header>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleSubmit,
            setValues,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Row>
                  <Col md="6">
                    <Input
                      label="Tanggal"
                      type="date"
                      name="tgl_transfer_produksi_alat_mesin"
                      value={values.tgl_transfer_produksi_alat_mesin}
                      onChange={(e) => {
                        getNoBaru(e.target.value, setValues, values);
                      }}
                      error={
                        errors.tgl_transfer_produksi_alat_mesin &&
                        touched.tgl_transfer_produksi_alat_mesin &&
                        true
                      }
                      errorText={errors.tgl_transfer_produksi_alat_mesin}
                    />
                  </Col>

                  <Col md="6">
                    <Input
                      label="No Transfer Alat Mesin"
                      type="text"
                      name="no_transfer_alat_mesin"
                      placeholder="Pilih tgl untik menentukan nomor"
                      value={values.no_transfer_alat_mesin}
                      readOnly={true}
                    />
                  </Col>
                </Row>

                <Input
                  label="Item Alat Mesin Produksi"
                  type="text"
                  name="nama_item_buaso"
                  value={values.nama_item_buaso}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      nama_item_buaso: e.target.value,
                    });
                  }}
                  error={
                    errors.nama_item_buaso && touched.nama_item_buaso && true
                  }
                  errorText={errors.nama_item_buaso}
                  readOnly={true}
                />

                <Input
                  label="Qty Item Alat Mesin"
                  type="number"
                  name="qty_transfer_produksi_alat_mesin"
                  value={
                    values.qty_transfer_produksi_alat_mesin
                      ? parseFloat(values.qty_transfer_produksi_alat_mesin)
                      : 0
                  }
                  onChange={(e) => {
                    setValues({
                      ...values,
                      qty_transfer_produksi_alat_mesin: e.target.value,
                      harga_satuan_kontrak_subkon: '',
                      jumlah: '',
                    });
                  }}
                  error={
                    errors.qty_transfer_produksi_alat_mesin &&
                    touched.qty_transfer_produksi_alat_mesin &&
                    true
                  }
                  errorText={errors.qty_transfer_produksi_alat_mesin}
                  readOnly={true}
                />
                <SelectSearch
                  label="Diserahkan"
                  name="id_karyawan"
                  option={dataKaryawan.map((val) => ({
                    value: val.id_karyawan,
                    label: val.nama_karyawan,
                  }))}
                  defaultValue={{
                    value: values.id_karyawan,
                    label: values.id_karyawan
                      ? dataKaryawan.find(
                          (val) => val.id_karyawan === values.id_karyawan
                        ).nama_karyawan
                      : 'Pilih Data Karyawan',
                  }}
                  onChange={(val) => {
                    setValues({
                      ...values,
                      id_karyawan: val.value,
                    });
                  }}
                  error={errors.id_karyawan && touched.id_karyawan && true}
                  errorText={errors.id_karyawan}
                />
                <TextArea
                  label="Keterangan"
                  name="keterangan"
                  value={values.keterangan ? values.keterangan : ''}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      keterangan: e.target.value,
                    });
                  }}
                  rows="4"
                  error={errors.keterangan && touched.keterangan && true}
                  errorText={errors.keterangan}
                />
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex justify-content-end">
                  <ActionButton
                    type="submit"
                    text="Simpan"
                    className="mt-2 px-4"
                    loading={isSubmitting}
                  />
                </div>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>

      <HapusModal />
    </>
  );
};

export default DataTransferAlatMesin;
