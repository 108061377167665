import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import _ from 'lodash';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  Table,
  Th,
  ThFixed,
  Td,
  TdFixed,
  SelectSearch,
} from 'components';
import { RupiahConvert } from 'utilities';
import { LaporanPenyusutanAsetApi } from 'api';
import { dropdownMap } from './Helpers';

export const LaporanPenyusutanAset = ({ setNavbarTitle }) => {
  const [isFetchingReport, setIsFetchingReport] = useState(false);
  const [data, setData] = useState([]);
  const [dropdown, setDropdown] = useState({
    grup_aset: [],
    kategori_aset: [],
    jenis_aset: [],
    item_aset: [],
  });
  const [loadingDropdown, setLoadingDropdown] = useState({
    grup_aset: false,
    kategori_aset: false,
    jenis_aset: false,
    item_aset: false,
  });
  const [searchFilter, setSearchFilter] = useState({
    id_grup_aset: '',
    id_kategori_aset: '',
    id_jenis_aset: '',
    id_item_aset: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getGrupAset = () => {
    setLoadingDropdown((prev) => ({
      ...prev,
      grup_aset: true,
    }));

    LaporanPenyusutanAsetApi.getDropdown({ tipe: 'grup' })
      .then((res) => {
        const defaultDropdown = { value: '0', label: 'Semua Grup Aset' };
        const data = res?.data?.data?.map(dropdownMap);

        setDropdown((prev) => ({
          ...prev,
          grup_aset: [defaultDropdown].concat(data),
        }));
      })
      .catch(() =>
        setDropdown((prev) => ({
          ...prev,
          grup_aset: [],
        }))
      )
      .finally(() =>
        setLoadingDropdown((prev) => ({
          ...prev,
          grup_aset: false,
        }))
      );
  };

  const getKategoriAset = (id_grup_aset) => {
    setLoadingDropdown((prev) => ({
      ...prev,
      kategori_aset: true,
    }));

    const query = { tipe: 'kategori' };
    if (id_grup_aset !== '0') query.id_grup_aset = id_grup_aset;

    LaporanPenyusutanAsetApi.getDropdown(query)
      .then((res) => {
        const defaultDropdown = { value: '0', label: 'Semua Kategori Aset' };
        const data = res?.data?.data?.map(dropdownMap);

        setDropdown({
          ...dropdown,
          kategori_aset: [defaultDropdown].concat(data),
        });
      })
      .catch(() =>
        setDropdown({
          ...dropdown,
          kategori_aset: [],
        })
      )
      .finally(() =>
        setLoadingDropdown((prev) => ({
          ...prev,
          kategori_aset: false,
        }))
      );
  };

  const getJenisAset = (id_kategori_aset) => {
    setLoadingDropdown((prev) => ({
      ...prev,
      jenis_aset: true,
    }));

    const query = { tipe: 'jenis' };

    if (id_kategori_aset !== '0') query.id_kategori_aset = id_kategori_aset;

    if (searchFilter.id_grup_aset !== '0')
      query.id_grup_aset = searchFilter.id_grup_aset;

    LaporanPenyusutanAsetApi.getDropdown(query)
      .then((res) => {
        const defaultDropdown = { value: '0', label: 'Semua Jenis Aset' };
        const data = res?.data?.data?.map(dropdownMap);

        setDropdown({
          ...dropdown,
          jenis_aset: [defaultDropdown].concat(data),
        });
      })
      .catch(() =>
        setDropdown({
          ...dropdown,
          jenis_aset: [],
        })
      )
      .finally(() =>
        setLoadingDropdown((prev) => ({
          ...prev,
          jenis_aset: false,
        }))
      );
  };

  const getItemAset = (id_jenis_aset) => {
    setLoadingDropdown((prev) => ({
      ...prev,
      item_aset: true,
    }));

    const query = { tipe: 'item_aset' };

    if (id_jenis_aset !== '0') query.id_jenis_aset = id_jenis_aset;

    if (searchFilter.id_grup_aset !== '0')
      query.id_grup_aset = searchFilter.id_grup_aset;

    if (searchFilter.id_kategori_aset !== '0')
      query.id_kategori_aset = searchFilter.id_kategori_aset;

    LaporanPenyusutanAsetApi.getDropdown(query)
      .then((res) => {
        const defaultDropdown = { value: '0', label: 'Semua Item Aset' };
        const data = res?.data?.data?.map(dropdownMap);

        setDropdown({
          ...dropdown,
          item_aset: [defaultDropdown].concat(data),
        });
      })
      .catch(() =>
        setDropdown({
          ...dropdown,
          item_aset: [],
        })
      )
      .finally(() =>
        setLoadingDropdown((prev) => ({ ...prev, item_aset: false }))
      );
  };

  const getReportData = () => {
    setIsFetchingReport(true);
    const query = {};

    if (searchFilter.id_grup_aset !== '0')
      query.id_grup_aset = searchFilter.id_grup_aset;

    if (searchFilter.id_kategori_aset !== '0')
      query.id_kategori_aset = searchFilter.id_kategori_aset;

    if (searchFilter.id_jenis_aset !== '0')
      query.id_jenis_aset = searchFilter.id_jenis_aset;

    if (searchFilter.id_item_aset !== '0')
      query.id_item_aset = searchFilter.id_item_aset;

    LaporanPenyusutanAsetApi.getReport(query)
      .then(({ data }) => setData(data?.data ?? []))
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        })
      )
      .finally(() => setIsFetchingReport(false));
  };

  useEffect(() => {
    setNavbarTitle('Laporan Penyusutan Aset');
    getGrupAset();

    return () => {
      setDropdown({});
      setSearchFilter({});
      setData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNavbarTitle]);

  const PageContent = () => {
    if (!data || data.length < 1) {
      return (
        <div style={{ minHeight: '45vh' }}>
          <DataStatus text="Tidak ada data" />
        </div>
      );
    }

    return (
      <Table>
        <thead>
          <tr>
            <ThFixed width="12px">No</ThFixed>
            <Th width="12px">Kode Aset</Th>
            <Th>Item Aset</Th>
            <Th>Grup Aset</Th>
            <Th>Kategori Aset</Th>
            <Th>Jenis Aset</Th>
            <Th>Penyusutan Garis Lurus Per Bulan</Th>
          </tr>
        </thead>
        <tbody>
          {data.map((val, index) => (
            <tr key={index}>
              <TdFixed>{index + 1}</TdFixed>
              <Td>{val?.kode_item_aset ?? '-'}</Td>
              <Td>{val?.nama_item_aset ?? '-'}</Td>
              <Td>{val?.nama_grup_aset ?? '-'}</Td>
              <Td>{val?.nama_kategori_aset ?? '-'}</Td>
              <Td>{val?.nama_jenis_aset ?? '-'}</Td>
              <Td textRight>
                {
                  RupiahConvert(
                    String(
                      parseInt(val?.penyusutan_garis_lurus_per_bulan || '0')
                    )
                  ).detail
                }
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  return (
    <CRUDLayout>
      <Card className="mb-3">
        <Card.Header>
          <strong>Pencarian Data</strong>
        </Card.Header>
        <Card.Body>
          {loadingDropdown.grup_aset ? (
            <DataStatus
              text="Memuat Data ..."
              loading={loadingDropdown.grup_aset}
            />
          ) : (
            <Row>
              <Col lg="2">
                <SelectSearch
                  label="Grup Aset"
                  placeholder="Pilih Grup Aset"
                  option={dropdown.grup_aset}
                  onChange={(val) => {
                    setSearchFilter({
                      id_grup_aset: val.value,
                      id_kategori_aset: '',
                      id_jenis_aset: '',
                      id_item_aset: '',
                    });
                    getKategoriAset(val.value);
                  }}
                />
              </Col>

              <Col lg="2">
                <SelectSearch
                  label="Kategori Aset"
                  placeholder="Pilih Kategori Aset"
                  option={dropdown.kategori_aset}
                  isDisabled={searchFilter.id_grup_aset === ''}
                  onChange={(val) => {
                    setSearchFilter({
                      ...searchFilter,
                      id_kategori_aset: val.value,
                      id_jenis_aset: '',
                      id_item_aset: '',
                    });
                    getJenisAset(val.value);
                  }}
                  loading={loadingDropdown.kategori_aset}
                />
              </Col>

              <Col lg="2">
                <SelectSearch
                  label="Jenis Aset"
                  placeholder="Pilih Jenis Aset"
                  option={dropdown.jenis_aset}
                  isDisabled={searchFilter.id_kategori_aset === ''}
                  onChange={(val) => {
                    setSearchFilter({
                      ...searchFilter,
                      id_jenis_aset: val.value,
                      id_item_aset: '',
                    });
                    getItemAset(val.value);
                  }}
                  loading={loadingDropdown.jenis_aset}
                />
              </Col>

              <Col lg="5">
                <SelectSearch
                  label="Item Aset"
                  placeholder="Pilih Item Aset"
                  option={dropdown.item_aset}
                  isDisabled={searchFilter.id_jenis_aset === ''}
                  onChange={(val) =>
                    setSearchFilter({
                      ...searchFilter,
                      id_item_aset: val.value,
                    })
                  }
                  loading={loadingDropdown.item_aset}
                />
              </Col>

              <Col lg="1" className="d-flex align-items-center pt-3">
                <ActionButton
                  text="Cari"
                  size="sm"
                  onClick={() => {
                    if (
                      searchFilter.id_grup_aset === '' ||
                      searchFilter.id_item_aset === '' ||
                      searchFilter.id_jenis_aset === '' ||
                      searchFilter.id_kategori_aset === ''
                    ) {
                      setAlertConfig({
                        show: true,
                        text: 'Kolom Pencarian Data harus diisi',
                        variant: 'danger',
                      });
                    } else {
                      getReportData();
                    }
                  }}
                />
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>

      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {isFetchingReport ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <div style={{ minHeight: '45vh' }}>
          <PageContent />
        </div>
      )}
    </CRUDLayout>
  );
};
