import React, { useState, useEffect } from 'react';
import {
  CRUDLayout,
  Input,
  InputSearch,
  CreateButton,
  ActionButton,
  DataStatus,
  DeleteModal,
  UpdateButton,
  DeleteButton,
  Alert,
  Pagination,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  SelectSearch,
  TextArea,
  Switch,
  Select,
} from '../../../components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import { PageNumber } from '../../../utilities';
import { Modal } from 'react-bootstrap';
import { JenisAsetApi } from '../../../api';
import { useIsGuest } from 'hooks';

const JenisAset = ({ setNavbarTitle }) => {
  // Title
  const title = 'Jenis Aset';
  const guest = useIsGuest();

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(true);

  // STATE SEARCHING
  const [searchKey, setSearchKey] = useState('');

  // MODALS SHOW STATE
  const [isDeleteData, setIsDeleteData] = useState(false); // MODAL HAPUS STATE
  const [modal, setModal] = useState({
    show: false,
    submit: 'Tambah',
  });

  // STATE DATA SATUAN
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [totalPage, setTotalPage] = useState(1);
  const [totalData, setTotalData] = useState('');
  const [dataGrup, setDataGrup] = useState([]);
  const [dataGolongan, setDataGolongan] = useState([]);
  // STATE MENAMPUNG DATA YANG AKAN DI UPDATE
  const [updateData, setUpdateData] = useState({
    id_jenis_aset: '',
    kode_jenis_aset: '',
    nama_jenis_aset: '',
    id_grup_aset: '',
    nama_grup_aset: 'Pilih Grup Aset',
    id_kategori_aset: '',
    nama_kategori_aset: 'Pilih Kategori Aset',
    id_golongan_aset: '',
    umur_ekonomis_tahun: 0,
    keterangan: '',
  });

  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
  });
  const [alertConfigSearch, setAlertConfigSearch] = useState({
    data: false,
    variant: 'primary',
    text: '',
  });

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true);
    setShowAlert(false);

    Axios.all([
      JenisAsetApi.getPage(page, dataLength, searchKey),
      JenisAsetApi.dropdown({
        tipe: 'grup_aset',
      }),
      JenisAsetApi.dropdown({
        tipe: 'golongan_aset',
      }),
    ])
      .then(
        Axios.spread((res, grup, golongan) => {
          setData(res.data.data);
          setTotalPage(res.data.total_page);
          setTotalData(res.data.data_count);
          setDataGrup(grup.data.data);
          setDataGolongan(golongan.data.data);
        })
      )
      .finally(() => {
        if (searchKey != '') {
          setAlertConfigSearch({
            data: true,
            variant: 'primary',
            text: `Hasil Pencarian : ${searchKey}`,
          });
        } else {
          setAlertConfigSearch({
            data: false,
            variant: '',
            text: '',
          });
        }
        setIsLoading(false);
      });
  };

  // FUNCTION SWITCH HIDDEN DATA
  const changeDataStatus = (status, value) => {
    setIsLoading(true);
    setShowAlert(false);

    const onLoadedSuccess = () => {
      setAlertConfig({
        variant: 'primary',
        text: 'Ubah status data berhasil',
      });
      setShowAlert(true);
    };

    const onLoadedFailed = () => {
      setAlertConfig({
        variant: 'danger',
        text: 'Ubah status data gagal',
      });
      setShowAlert(true);
    };

    status === true
      ? JenisAsetApi.show(value)
          .then(() => {
            getData();
            onLoadedSuccess();
          })
          .catch(() => {
            getData();
            onLoadedFailed();
          })
      : JenisAsetApi.hide(value)
          .then(() => {
            getData();
            onLoadedSuccess();
          })
          .catch(() => {
            getData();
            onLoadedFailed();
          });
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title); // SET JUDUL NAVBAR
    getData(); // GET DATA DARI SERVER

    return () => {
      setIsLoading(false);
    };
  }, [setNavbarTitle, page, dataLength, searchKey]);

  const formInitialValues = {
    id_jenis_aset: updateData.id_jenis_aset,
    kode_jenis_aset: updateData.kode_jenis_aset,
    nama_jenis_aset: updateData.nama_jenis_aset,
    id_grup_aset: updateData.id_grup_aset,
    nama_grup_aset: updateData.nama_grup_aset,
    id_kategori_aset: updateData.id_kategori_aset,
    nama_kategori_aset: updateData.nama_kategori_aset,
    id_golongan_aset: updateData.id_golongan_aset,
    umur_ekonomis_tahun: updateData.umur_ekonomis_tahun,
    keterangan: updateData.keterangan,
  };

  const DataModal = () => {
    const [dataKategori, setDataKategori] = useState([]);
    const [loadingKategori, setLoadingKategori] = useState(false);
    const [dataCek, setDataCek] = useState({
      id_grup_aset: '',
      id_kategori_aset: '',
    });

    const getDataKategori = (value) => {
      setLoadingKategori(true);

      JenisAsetApi.dropdown({
        tipe: 'kategori_aset',
        id_grup_aset: value,
      })
        .then((res) => setDataKategori(res.data.data))
        .finally(() => setLoadingKategori(false));
    };

    useEffect(() => {
      updateData.id_grup_aset != '' && getDataKategori(updateData.id_grup_aset);

      setDataCek({
        id_grup_aset: updateData.id_grup_aset,
        id_kategori_aset: updateData.id_kategori_aset,
      });
    }, [updateData]);

    // FORMIK VALIDATION SCHEMA DENGAN YUP VALIDATION
    const formValidationSchema = Yup.object().shape({
      kode_jenis_aset: Yup.string()
        .required('Kode Jenis Aset wajib diisi')
        .test('checkKode', 'Kode Jenis Aset telah didaftarkan', (value) =>
          updateData.kode_jenis_aset === value
            ? true
            : JenisAsetApi.single({
                id_grup_aset: dataCek.id_grup_aset,
                id_kategori_aset: dataCek.id_kategori_aset,
                kode_jenis_aset: value,
              })
                .then(() => false)
                .catch(() => true)
        ),
      nama_jenis_aset: Yup.string().required('Nama Jenis Aset wajib diisi'),
      id_grup_aset: Yup.string().required('Pilih Grup Aset'),
      id_kategori_aset: Yup.string().required('Pilih Kategori Aset'),
      id_golongan_aset: Yup.string().required('Pilih Golongan Aset'),
      umur_ekonomis_tahun: Yup.number()
        .typeError('Umur Ekonomis harus berupa angka')
        .min('0', 'Minimal diisi dengan angka 0')
        .required('Umur Ekonomis wajib diisi'),
    });

    const formSubmitHandler = (values) => {
      if (modal.submit == 'Tambah') {
        JenisAsetApi.create({
          kode_jenis_aset: values.kode_jenis_aset,
          nama_jenis_aset: values.nama_jenis_aset,
          id_grup_aset: values.id_grup_aset,
          id_kategori_aset: values.id_kategori_aset,
          id_golongan_aset: values.id_golongan_aset,
          umur_ekonomis_tahun: values.umur_ekonomis_tahun,
          keterangan: values.keterangan,
        })
          .then((res) => {
            // SUCCESS ALERT
            setAlertConfig({
              variant: 'primary',
              text: 'Tambah data berhasil!',
            });
          })
          .catch((err) => {
            // ERROR ALERT
            setAlertConfig({
              variant: 'danger',
              text: `Tambah data gagal! (${err.response.data.message})`,
            });
          })
          .finally(() => {
            // CLOSE MODAL
            setModal({
              ...modal,
              show: false,
            });
            // FETCH DATA DARI SERVER
            getData();
            // TAMPILKAN ALERT
            setShowAlert(true);
          });
      } else if (modal.submit == 'Ubah') {
        JenisAsetApi.update({
          id_jenis_aset: values.id_jenis_aset,
          kode_jenis_aset: values.kode_jenis_aset,
          nama_jenis_aset: values.nama_jenis_aset,
          id_grup_aset: values.id_grup_aset,
          id_kategori_aset: values.id_kategori_aset,
          id_golongan_aset: values.id_golongan_aset,
          umur_ekonomis_tahun: values.umur_ekonomis_tahun,
          keterangan: values.keterangan,
        })
          .then((res) => {
            // SUCCESS ALERT
            setAlertConfig({
              variant: 'primary',
              text: 'Ubah data berhasil!',
            });
          })
          .catch((err) => {
            // ERROR ALERT
            setAlertConfig({
              variant: 'danger',
              text: `Ubah data gagal! (${err.response.data.message})`,
            });
          })
          .finally(() => {
            // CLOSE MODAL
            setModal({
              ...modal,
              show: false,
            });
            // FETCH DATA DARI SERVER
            getData();
            // TAMPILKAN ALERT
            setShowAlert(true);
          });
      }
    };

    return (
      <Modal
        show={modal.show}
        onHide={() =>
          setModal({
            ...modal,
            show: false,
          })
        }
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-primary">
            <h6 className="mb-0">{`${modal.submit} ${title}`}</h6>
          </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <SelectSearch
                  label="Grup Aset"
                  name="id_grup_aset"
                  defaultValue={{
                    value: values.id_grup_aset,
                    label: values.nama_grup_aset,
                  }}
                  option={dataGrup.map((val) => {
                    return {
                      value: val.id_grup_aset,
                      label: val.nama_grup_aset,
                    };
                  })}
                  onChange={(val) => {
                    setFieldValue('id_grup_aset', val.value);
                    setFieldValue('nama_grup_aset', val.label);
                    setFieldValue('id_kategori_aset', '');
                    setFieldValue('nama_kategori_aset', 'Pilih Kategori Aset');
                    setFieldValue('kode_jenis_aset', '');

                    setDataCek({
                      id_grup_aset: val.value,
                    });

                    getDataKategori(val.value);
                  }}
                  error={errors.id_grup_aset && touched.id_grup_aset && true}
                  errorText={
                    errors.id_grup_aset &&
                    touched.id_grup_aset &&
                    errors.id_grup_aset
                  }
                />

                <SelectSearch
                  label="Kategori Aset"
                  name="id_kategori_aset"
                  defaultValue={{
                    value: values.id_kategori_aset,
                    label: values.nama_kategori_aset,
                  }}
                  option={dataKategori.map((val) => {
                    return {
                      value: val.id_kategori_aset,
                      label: val.nama_kategori_aset,
                    };
                  })}
                  onChange={(val) => {
                    setFieldValue('id_kategori_aset', val.value);
                    setFieldValue('nama_kategori_aset', val.label);
                    setFieldValue('kode_jenis_aset', '');

                    setDataCek({
                      ...dataCek,
                      id_kategori_aset: val.value,
                    });
                  }}
                  error={
                    errors.id_kategori_aset && touched.id_kategori_aset && true
                  }
                  errorText={
                    errors.id_kategori_aset &&
                    touched.id_kategori_aset &&
                    errors.id_kategori_aset
                  }
                  loading={loadingKategori}
                  isDisabled={values.id_grup_aset == '' ? true : false}
                />

                <Input
                  label="Kode Jenis Aset"
                  type="text"
                  name="kode_jenis_aset"
                  value={values.kode_jenis_aset}
                  onChange={handleChange}
                  error={
                    errors.kode_jenis_aset && touched.kode_jenis_aset && true
                  }
                  errorText={errors.kode_jenis_aset}
                  readOnly={
                    values.id_kategori_aset == '' ||
                    values.id_kategori_aset == null
                      ? true
                      : false
                  }
                />

                <Input
                  label="Nama Jenis Aset"
                  type="text"
                  name="nama_jenis_aset"
                  value={values.nama_jenis_aset}
                  onChange={handleChange}
                  error={
                    errors.nama_jenis_aset && touched.nama_jenis_aset && true
                  }
                  errorText={errors.nama_jenis_aset}
                />

                <Select
                  label="Golongan Aset"
                  name="id_golongan_aset"
                  onChange={handleChange}
                  defaultValue={values.id_golongan_aset} // ISI DEFAULT VALUE DENGAN id_golongan_aset yang memiliki value bahan
                  error={
                    errors.id_golongan_aset && touched.id_golongan_aset && true
                  }
                  errorText={errors.id_golongan_aset}
                >
                  {/* OPTIONS */}
                  <option value="" hidden>
                    Pilih Golongan Aset
                  </option>
                  {dataGolongan.map((e) => (
                    <option key={e.id_golongan_aset} value={e.id_golongan_aset}>
                      {e.nama_golongan_aset}
                    </option>
                  ))}
                </Select>

                <Input
                  label="Umur Ekonomis (Tahun)"
                  type="text"
                  name="umur_ekonomis_tahun"
                  value={values.umur_ekonomis_tahun}
                  onChange={handleChange}
                  error={
                    errors.umur_ekonomis_tahun &&
                    touched.umur_ekonomis_tahun &&
                    true
                  }
                  errorText={errors.umur_ekonomis_tahun}
                />

                <TextArea
                  label="Keterangan"
                  type="text"
                  name="keterangan"
                  value={values.keterangan}
                  onChange={handleChange}
                  error={errors.keterangan && touched.keterangan && true}
                  errorText={errors.keterangan}
                  rows={2}
                />
              </Modal.Body>
              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant="success"
                  text={modal.submit}
                  className="mt-2 px-4"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>
    );
  };

  // MODAL HAPUS COMPONENT
  const HapusModal = () => {
    // SET DATA ID YANG DIHAPUS
    const deleteValue = { id_jenis_aset: updateData.id_jenis_aset };

    // MENANGANI DELETE BUTTON LOADING
    const [btnLoading, setBtnLoading] = useState(false);

    // DELETE DATA DARI SERVER
    const deleteDataHandler = () => {
      setBtnLoading(true);

      JenisAsetApi.delete(deleteValue)
        .then(() => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: 'primary',
            text: 'Hapus data berhasil!',
          });
        })
        .catch((err) => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: 'danger',
            text: `Hapus data gagal! (${err.response.data.message})`,
          });
        })
        .finally(() => {
          // CLOSE MODAL
          setIsDeleteData(false);
          // FETCH DATA DARI SERVER
          getData();
          // TAMPIL ALERT
          setShowAlert(true);
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <div>Kode Jenis Aset : {updateData.kode_jenis_aset}</div>
        <div>Nama Jenis Aset : {updateData.nama_jenis_aset}</div>
      </DeleteModal>
    );
  };

  // TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              {guest ? '' : <ThFixed>Aksi</ThFixed>}
              <Th>Kode Jenis Aset</Th>
              <Th>Nama Jenis Aset</Th>
              <Th>Kategori Aset</Th>
              <Th>Grup Aset</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              return (
                <Tr key={val.id_jenis_aset}>
                  <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
                  {guest ? (
                    ''
                  ) : (
                    <TdFixed>
                      <div className="d-flex justify-content-center">
                        <UpdateButton
                          onClick={() => {
                            setUpdateData({
                              ...val,
                              id_grup_aset:
                                val.id_grup_aset == null
                                  ? ''
                                  : val.id_grup_aset,
                              nama_grup_aset:
                                val.nama_grup_aset == null ||
                                val.nama_grup_aset == ''
                                  ? 'Pilih Grup Aset'
                                  : val.nama_grup_aset,
                              id_kategori_aset:
                                val.id_kategori_aset == null
                                  ? ''
                                  : val.id_kategori_aset,
                              nama_kategori_aset:
                                val.nama_kategori_aset == null ||
                                val.nama_kategori_aset == ''
                                  ? 'Pilih Kategori Aset'
                                  : val.nama_kategori_aset,
                              id_golongan_aset:
                                val.id_golongan_aset == null
                                  ? ''
                                  : val.id_golongan_aset,
                            });
                            setModal({
                              show: true,
                              submit: 'Ubah',
                            });
                          }}
                        />
                        <DeleteButton
                          onClick={() => {
                            setUpdateData(val);
                            setIsDeleteData(true);
                          }}
                        />
                        <Switch
                          id={val.id_jenis_aset}
                          checked={val.is_hidden ? false : true}
                          onChange={() =>
                            changeDataStatus(val.is_hidden, {
                              id_jenis_aset: val.id_jenis_aset,
                            })
                          }
                        />
                      </div>
                    </TdFixed>
                  )}
                  <Td>{val.kode_jenis_aset}</Td>
                  <Td>{val.nama_jenis_aset}</Td>
                  <Td>{val.nama_kategori_aset}</Td>
                  <Td>{val.nama_grup_aset}</Td>
                </Tr>
              );
            })}
          </TBody>
        </CRUDLayout.Table>
        <Pagination
          dataLength={dataLength}
          dataPage={
            totalData <= 10
              ? data.length
              : data.map((res, index) => {
                  if (index == data.length - 1) {
                    return PageNumber(page, dataLength, index);
                  }
                })
          }
          dataNumber={data.map((res, index) => {
            if (index == 0) {
              return PageNumber(page, dataLength, index);
            }
          })}
          dataCount={totalData}
          onDataLengthChange={(e) => {
            setDataLength(e.target.value);
            setPage(1);
          }}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={(e) => {
                setTimeout(() => {
                  setSearchKey(e.target.value);
                  setPage(1);
                }, 1000);
              }}
              onSubmit={(e) => e.preventDefault()}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        {/* Button Section */}
        <CRUDLayout.HeadButtonSection>
          {/* Button Export */}
          {/* <ExportButton /> */}
          {/* Button Tambah */}
          <CreateButton
            onClick={() => {
              setUpdateData({
                id_jenis_aset: '',
                kode_jenis_aset: '',
                nama_jenis_aset: '',
                id_grup_aset: '',
                nama_grup_aset: 'Pilih Grup Aset',
                id_kategori_aset: '',
                nama_kategori_aset: 'Pilih Kategori Aset',
                id_golongan_aset: '',
                umur_ekonomis_tahun: 0,
                keterangan: '',
              });

              setModal({
                show: true,
                submit: 'Tambah',
              });
            }}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      <Alert
        show={alertConfigSearch.data}
        showCloseButton={true}
        variant={alertConfigSearch.variant}
        text={alertConfigSearch.text}
        onClose={() =>
          setAlertConfigSearch({
            data: false,
            variant: 'primary',
            text: '',
          })
        }
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : !Array.isArray(data) ? (
        <DataStatus text="Tidak dapat memuat data" />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {/* MODAL */}
      <DataModal />
      <HapusModal />
    </CRUDLayout>
  );
};

export default JenisAset;
