import { ButtonGroup } from 'react-bootstrap';
import {
  Table,
  THead,
  TBody,
  Th,
  ThFixed,
  Td,
  TdFixed,
  Tr,
  UpdateButton,
  DeleteButton,
  DataStatus,
} from 'components';
import { DateConvert } from 'utilities';
import { useContext } from 'react';
import { TransferAlatMesinContext } from '../Context';

export const TableTransferMP = ({ tipe = 'editable', data = [] }) => {
  const isEditable = tipe === 'editable';
  const { setModalForm, setDataTransfer } = useContext(
    TransferAlatMesinContext
  );

  const deleteItemTransfer = (index) => {
    setDataTransfer((prv) => prv.toSpliced(index, 1));
  };

  return (
    <Table>
      <THead>
        <Tr className="text-center">
          <ThFixed>No.</ThFixed>
          <ThFixed>Informasi Transfer Alat Mesin Produksi</ThFixed>
          <Th>Item Alat Mesin</Th>
          <Th>Qty. </Th>
          <Th>Satuan</Th>
          <Th>Diserahkan Kepada</Th>
          <Th>Keterangan</Th>
          {isEditable && <ThFixed>Aksi</ThFixed>}
        </Tr>
      </THead>

      <TBody>
        {data?.length ? (
          data.map((val, index) => (
            <Tr key={index}>
              <TdFixed className="text-center">{index + 1}</TdFixed>
              <Td>
                <div>
                  {val.tgl_transfer_produksi_alat_mesin
                    ? DateConvert(
                        new Date(val.tgl_transfer_produksi_alat_mesin)
                      ).defaultDMY
                    : '-'}
                </div>
                {val.no_transfer_produksi_alat_mesin}
              </Td>
              <Td>{val.nama_item_aset}</Td>
              <Td textRight>
                {parseFloat(val.qty_transfer_produksi_alat_mesin)}
              </Td>
              <Td>{val.nama_satuan_pakai ?? ''}</Td>
              <Td>{val.nama_karyawan}</Td>
              <Td>{val.keterangan}</Td>
              {isEditable && (
                <Td>
                  <ButtonGroup>
                    <UpdateButton
                      className="m-0"
                      onClick={() =>
                        setModalForm({
                          index,
                          show: true,
                          action: 'update',
                          data: val,
                        })
                      }
                    />
                    <DeleteButton
                      className="m-0"
                      onClick={() => deleteItemTransfer(index)}
                    />
                  </ButtonGroup>
                </Td>
              )}
            </Tr>
          ))
        ) : (
          <Tr>
            <Td colSpan={isEditable ? 9 : 8}>
              <DataStatus text="Tidak ada data" />
            </Td>
          </Tr>
        )}
      </TBody>
    </Table>
  );
};
