import { Services } from 'services';

class PemeliharaanAsetApi {
  get(value) {
    return Services.get('/pemeliharaan_aset/page', { params: { ...value } });
  }

  getSingle(value) {
    return Services.get('/pemeliharaan_aset/single', { params: { ...value } });
  }

  getNomor(value) {
    return Services.get('/pemeliharaan_aset/no_baru', { params: { ...value } });
  }

  getDropdown(value) {
    return Services.get('/pemeliharaan_aset/dropdown', {
      params: { ...value },
    });
  }

  create(value) {
    return Services.post('/pemeliharaan_aset', value);
  }

  update(value) {
    return Services.put('/pemeliharaan_aset', value);
  }
}

export default new PemeliharaanAsetApi();
