import { Services } from '../../../services';

class LaporanPenyusutanAsetApi {
  getReport(params) {
    return Services.get('/laporan_penyusutan', { params });
  }

  getDropdown(params) {
    // params : grup | kategori | jenis | item_aset
    return Services.get('/laporan_penyusutan/dropdown', { params });
  }
}

export default new LaporanPenyusutanAsetApi();
