import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col, ButtonGroup } from 'react-bootstrap';
import { IoEyeOutline } from 'react-icons/io5';
import _ from 'lodash';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  UpdateButton,
  ReadButton,
  InputSearch,
  Pagination,
  Table,
  Th,
  Td,
  ThFixed,
  TdFixed,
  CreateButton,
  Select,
} from 'components';
import { DateConvert, PageNumber as TableNumber } from 'utilities';
import { PemeliharaanAsetApi } from 'api';
import { ModalSection } from './Section';

const ListPemeliharaanAset = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataPemeliharaanAset, setDataPemeliharaanAset] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0',
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    id: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    PemeliharaanAsetApi.get({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then(({ data }) => {
        setDataPemeliharaanAset(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setPaginationConfig({
      page: '1',
      dataLength: '10',
      totalPage: '1',
      dataCount: '0',
    });
    setAlertConfig({
      show: key ? true : false,
      variant: 'primary',
      text: 'Hasil dari pencarian: ' + key,
    });
  };

  const generateStatusApproval = (status) => {
    if (status?.toUpperCase() === 'APP') {
      return 'Approve';
    } else if (status?.toUpperCase() === 'REJ') {
      return 'Reject';
    } else if (status?.toUpperCase() === 'REV') {
      return 'Revisi';
    } else if (status?.toUpperCase() === 'PEN') {
      return 'Pending';
    }

    return '-';
  };

  useEffect(() => {
    setNavbarTitle('List Pemeliharaan Aset');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.key,
    paginationConfig.page,
    paginationConfig.dataLength,
  ]);

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <thead>
          <tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <Th noPadding width={70}>
              Tgl. Pemeliharaan Aset
            </Th>
            <Th noPadding width={80}>
              Kode. Pemeliharaan Aset
            </Th>
            <Th>Kode Aset</Th>
            <Th width={200}>Item Aset</Th>
            <Th>Grup Aset</Th>
            <Th noPadding>Unit Organisasi Pengguna</Th>
            <Th>Status Pemeliharaan</Th>
            <Th>Status Approval</Th>
          </tr>
        </thead>
        <tbody>
          {dataPemeliharaanAset.map((val, index) => (
            <tr key={index}>
              <Td textCenter>
                {TableNumber(
                  paginationConfig.page,
                  paginationConfig.dataLength,
                  index
                )}
              </Td>
              <Td>
                <div className="d-flex">
                  <ReadButton
                    onClick={() =>
                      setModalConfig({
                        show: true,
                        type: 'detail',
                        id: val.id_pemeliharaan_aset,
                      })
                    }
                  />
                  {Boolean(
                    val?.status_approval?.toUpperCase() === 'REV' ||
                      val?.status_approval?.toUpperCase() === 'PEN'
                  ) && (
                    <UpdateButton
                      onClick={() =>
                        setModalConfig({
                          show: true,
                          type: 'update',
                          id: val.id_pemeliharaan_aset,
                        })
                      }
                    />
                  )}
                </div>
              </Td>
              <Td>
                {val.tgl_pemeliharaan_aset
                  ? DateConvert(new Date(val.tgl_pemeliharaan_aset)).defaultDMY
                  : '-'}
              </Td>
              <Td>{val.no_pemeliharaan_aset ?? '-'}</Td>
              <Td>{val.kode_item_aset ?? '-'}</Td>
              <Td>{val.nama_item_aset ?? '-'}</Td>
              <Td>{val.nama_grup_aset ?? '-'}</Td>
              <Td>{val.nama_unit_organisasi ?? '-'}</Td>
              <Td>{val.status_pemeliharaan_aset ?? '-'}</Td>
              <Td className="text-uppercase text-center">
                {generateStatusApproval(val.status_approval)}
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
    );

    if (!dataPemeliharaanAset || dataPemeliharaanAset.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-2">
            <Col md={8}>
              <InputSearch
                value={searchConfig.key}
                onChange={onInputSearchChange}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() =>
              setModalConfig({
                show: true,
                type: 'create',
                id: '',
              })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}

      {/* Modal */}
      {modalConfig.show && (
        <ModalSection
          alertConfig={alertConfig}
          modalConfig={modalConfig}
          setAlertConfig={setAlertConfig}
          setModalConfig={setModalConfig}
          refreshList={getInitialData}
        />
      )}
    </CRUDLayout>
  );
};

export default ListPemeliharaanAset;
