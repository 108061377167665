import { Services, UploadServices } from '../../../../services';

class AsetInventarisKantorApi {
  getPage(page, dataLength, key) {
    return Services.get(
      `/asetinventariskantor/page/?page=${page}&per_page=${dataLength}&q=${key}`
    );
  }
  getSingle(id) {
    return Services.get(`/asetinventariskantor/single/?id_item_aset=${id}`);
  }
  getKode() {
    return Services.get('/asetinventariskantor/no_baru?id_kategori_aset=18');
  }
  create(data) {
    return Services.post('/asetinventariskantor', data);
  }
  update(data) {
    return Services.put('/asetinventariskantor', data);
  }
  delete(id_grupaset) {
    return Services.post('/asetinventariskantor/delete', id_grupaset);
  }
  show(id_grupaset) {
    return Services.put('/asetinventariskantor/show', id_grupaset);
  }
  hide(id_grupaset) {
    return Services.put('/asetinventariskantor/hide', id_grupaset);
  }
  getKategoriAset() {
    return Services.get('/asetinventariskantor/kategori');
  }
  getPabrikan() {
    return Services.get('/pabrikan/dropdown');
  }
  getPengguna() {
    return Services.get('/unit_organisasi/dropdown');
  }
  getPenanggungJawab() {
    return Services.get('/karyawan/dropdown');
  }
  postPabrikan(value) {
    return Services.post('/pabrikan', value);
  }
  getGolongan() {
    return Services.get('/asetinventariskantor/dropdown_golongan_aset');
  }
  getKondisi() {
    return Services.get('/asetinventariskantor/dropdown_kondisi_aset');
  }
  getJenis() {
    return Services.get('/asetinventariskantor/dropdown_jenis_aset');
  }
  uploadFileMultiple(data) {
    return UploadServices.post('/upload/aset_multiple', data);
  }

  uploadFile(data) {
    return Services.post('/asetinventariskantor/gambar', data);
  }

  deleteFile(id) {
    return Services.post('/asetinventariskantor/gambar_delete', id);
  }

  dropdown(value) {
    return Services.get('/asetinventariskantor/dropdown', {
      params: { ...value },
    });
  }

  single(value) {
    return Services.get('/asetinventariskantor/single', {
      params: { ...value },
    });
  }

  singleCheck(value) {
    return Services.get('/asetinventariskantor/single_check', {
      params: { ...value },
    });
  }

  no_baru(value) {
    return Services.get('/asetinventariskantor/no_baru', {
      params: { ...value },
    });
  }

  penyusutan(value) {
    return Services.get('/penyusutan_saldo_menurun/penyusutan', {
      params: { ...value },
    });
  }

  updatePenyusutan(value) {
    return Services.put('/asetinventariskantor/penyusutan', value);
  }
  export() {
    return Services.get('/asetinventariskantor/xlsx');
  }
}

export default new AsetInventarisKantorApi();
