// React
import React, { useState, useCallback } from 'react';

// API
import { AsetTanahApi } from '../../../../../../api';

// Component
import _ from 'lodash';
import {
  ActionButton,
  DataStatus,
  Alert,
  CRUDLayout,
  THead,
  Td,
  Tr,
  TBody,
  Th,
  ThFixed,
  TdFixed,
  DeleteButton,
  DownloadButton,
} from '../../../../../../components';
import { Col, Row } from 'react-bootstrap';

// Icon
import { IoCloudUploadOutline } from 'react-icons/io5';

// Upload File
import { useDropzone } from 'react-dropzone';
import ImageViewer from 'react-simple-image-viewer';

const BerkasAset = ({ tipe, data, getDataSingle }) => {
  console.log('data', data);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
  });
  const [currentImage, setCurrentImage] = useState('');
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const path = require('path');
  const typeImage = ['.jpg', '.png', '.gif', '.jpeg'];
  const typeDoc = ['.pdf', '.xlsx', '.dwg', '.docx', '.skp'];

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.jpg, .png, .gif, .jpeg, .pdf, .xlsx, .dwg, .docx, .skp',
    onDrop: (acceptedFiles) => {
      let id = data.id_item_aset;
      const formData = new FormData();

      acceptedFiles.reduce((acc, file, index) => {
        formData.append('file_' + index, file);
        return file;
      }, {});
      AsetTanahApi.uploadFileMultiple(formData).then((data) => {
        let valueUpload = {
          id_item_aset: id,
          file: data.data.data,
        };

        AsetTanahApi.uploadFile(valueUpload)
          .then(() => {})
          .finally(() => {
            getDataSingle();
          });
      });
    },
  });

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  const removeFile = (i) => {
    let initialRemove = { file: [i] };
    AsetTanahApi.deleteFile(initialRemove)
      .then(() => {})
      .finally(() => {
        getDataSingle();
      });
  };

  const filesViewer = data.file
    .filter((res) => {
      return typeImage.includes(path.extname(res.image_url));
    })
    .map((res) => res.image_url);

  const thumbs = data.file
    .filter((res) => {
      return typeImage.includes(path.extname(res.image_url));
    })
    .map((val, index) => {
      return (
        <div className="col-lg-3 col-md-4 col-sm-6">
          <div>
            <img
              src={val.image_url}
              className="w-100 img-thumbnail"
              alt=""
              style={{ objectFit: 'cover', height: '200px' }}
              onClick={() => openImageViewer(index)}
            />
            {tipe != 'detail' && (
              <>
                <br />
                <div className="text-center">
                  <ActionButton
                    className="mt-2 mb-4"
                    variant="danger"
                    text="Delete"
                    size="sm"
                    onClick={() => removeFile(val.id_gambar)}
                  />
                </div>
              </>
            )}
          </div>
          {isViewerOpen && (
            <ImageViewer
              src={filesViewer}
              currentIndex={currentImage}
              disableScroll={false}
              onClose={closeImageViewer}
            />
          )}
        </div>
      );
    });

  const viewDoc = (val) => {
    window.open(val);
  };

  const doc = (
    <CRUDLayout.Table>
      <THead>
        <Tr>
          <ThFixed>No.</ThFixed>
          <Th>Nama File</Th>
          {tipe != 'detail' && <ThFixed>Aksi</ThFixed>}
        </Tr>
      </THead>
      <TBody>
        {data.file
          .filter((res) => {
            return typeDoc.includes(path.extname(res.image_url));
          })
          .map((val, index) => (
            <Tr key={val.index}>
              <TdFixed>{index + 1}</TdFixed>
              <Td>
                {val.image_url.split('/')[val.image_url.split('/').length - 1]}
              </Td>
              {tipe != 'detail' && (
                <TdFixed>
                  <div>
                    <div className="d-flex justify-content-center">
                      <DownloadButton
                        onClick={() => {
                          viewDoc(val.image_url);
                        }}
                      />
                      <DeleteButton onClick={() => removeFile(val.id_gambar)} />
                    </div>
                  </div>
                </TdFixed>
              )}
            </Tr>
          ))}
      </TBody>
    </CRUDLayout.Table>
  );

  return (
    <>
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />
      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : (
        <div className="bg-white rounded">
          <div className="mx-3">
            {tipe != 'detail' && (
              <Row>
                <Col lg={2} />
                <Col>
                  <section
                    className="bg-light my-4 text-center py-4 rounded-pill"
                    style={{ border: '3px dashed #eeeeee' }}
                  >
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />
                      <IoCloudUploadOutline size={50} />
                      <p>
                        Drag 'n' drop some files here, or click to select files
                      </p>
                    </div>
                  </section>
                </Col>
                <Col lg={2} />
              </Row>
            )}
            {data.file.length > 0 ? (
              <>
                <div className="row pt-3">{thumbs}</div>

                <Row>
                  <Col style={{ marginTop: 50 }}>{doc}</Col>
                </Row>
              </>
            ) : (
              <h3 className="text-center py-3">Data Kosong</h3>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default BerkasAset;
