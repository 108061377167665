/*eslint-disable*/

import {
  IoServerOutline,
  IoSpeedometerOutline,
  IoCashOutline,
  IoDocumentTextOutline,
} from 'react-icons/io5';
import Logo from '../assets/image/LogoSadhana.png';
import {
  // Master
  GroupAset,
  KondisiAset,
  KategoriAset,
  Pabrikan,
  AsetBangunan,
  AsetInventarisKantor,
  AsetKendaraan,
  AsetMesinDanAlat,
  AsetTanah,
  EntitasAset,
  JenisAset,
  DataAsetTanah,
  DataAsetBangunan,
  AsetSistem,
  DataAsetSistem,
  DataAsetKendaraan,
  DataAsetInventarisKantor,
  DataAsetMesinDanAlat,
  AsetLain,
  DataAsetLain,

  // Transaksi
  // Transfer Produksi Alat Mesin
  TransferProduksiAlatMesinList,
  TransferProduksiAlatMesinPreform,
  TambahTransferAlatMesin,
  DataTransferProduksiAlatMesin,

  // Pemeliharaan Aset
  ListPemeliharaanAset,

  // Laporan
  LaporanPenyusutanAset,
} from '../pages/ASM';

export default {
  LOGO: Logo,
  MODUL: 'ASET',
  isDev: true,

  /* 
    MENU REQUIREMENTS
      > TEXT  = REQUIRED,
      > LINK  = REQUIRED,
      > EXACT = OPTIONAL (TRUE/FALSE),
      > TYPE  = REQUIRED (MENU/DROPDOWN)
      > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
      > LOGO  = OPTIONAL 
  */

  MENU: [
    {
      text: 'Dashboard',
      link: '/',
      type: 'menu',
      exact: true,
      icon: <IoSpeedometerOutline />,
      hak: ['ASM'],
    },
    {
      text: 'Master Data',
      type: 'dropdown',
      icon: <IoServerOutline />,
      hak: ['ASM'],
      menu: [
        {
          text: 'Entitas Aset',
          link: '/asm/master/entitas-aset',
          hak: ['ASM', 'ASM_MAS_ENT'],
        },
        {
          text: 'Grup Aset',
          link: '#',
          hak: ['ASM'],
          menu: [
            {
              text: 'Aset Tanah',
              link: '/asm/master/item-aset/aset-tanah',
              hak: ['ASM', 'ASM_MAS_TNH'],
            },
            {
              text: 'Aset Bangunan',
              link: '/asm/master/item-aset/aset-bangunan',
              hak: ['ASM', 'ASM_MAS_BGN'],
            },
            {
              text: 'Aset Kendaraan',
              link: '/asm/master/item-aset/aset-kendaraan',
              hak: ['ASM', 'ASM_MAS_KDR'],
            },
            {
              text: 'Aset Alat dan Mesin',
              link: '/asm/master/item-aset/aset-alat-dan-mesin',
              hak: ['ASM', 'ASM_MAS_ALM'],
            },
            {
              text: 'Aset Inventaris',
              link: '/asm/master/item-aset/aset-inventaris-kantor',
              hak: ['ASM', 'ASM_MAS_IVK'],
            },
            {
              text: 'Aset Sistem-sistem',
              link: '/asm/master/item-aset/aset-sistem',
              hak: ['ASM', 'ASM_MAS_ASS'],
            },
            {
              text: 'Aset Lainnya',
              link: '/asm/master/item-aset/aset-lain',
              hak: ['ASM', 'ASM_MAS_ASL'],
            },
          ],
        },
        {
          text: 'Kategori Aset',
          link: '/asm/master/kategori-aset',
          hak: ['ASM', 'ASM_MAS_KTG'],
        },
        {
          text: 'Jenis Aset',
          link: '/asm/master/jenis-aset',
          hak: ['ASM', 'ASM_MAS_JEN'],
        },
        {
          text: 'Kondisi Aset',
          link: '/asm/master/kondisi-aset',
          hak: ['ASM', 'ASM_MAS_KDA'],
        },
        {
          text: 'Pabrikan Aset',
          link: '/asm/master/pabrikan',
          hak: ['ASM', 'ASM_MAS_PAB'],
        },
      ],
    },
    {
      text: 'Transaksi',
      type: 'dropdown',
      icon: <IoCashOutline />,
      hak: ['SUPA', 'ASM', 'ASM_TRN_TPA'],
      menu: [
        {
          text: 'Transfer Alat Mesin Produksi',
          link: '/transaksi/transfer-produksi-alat-mesin',
          hak: ['SUPA', 'ASM_TRN_TPA'],
        },
        {
          text: 'Pemeliharaan Aset',
          link: '/transaksi/pemeliharaan-aset',
          hak: ['SUPA', 'ASM_TRN_PA'],
        },
      ],
    },
    {
      text: 'Laporan',
      type: 'dropdown',
      icon: <IoDocumentTextOutline />,
      hak: ['ASM'],
      menu: [
        {
          text: 'Laporan Penyusutan Aset',
          link: '/laporan/laporan-penyusutan-aset',
          hak: ['SUPA', 'ASM_RPT_LPA'],
        },
      ],
    },

    //{
    //   text: "Profil",
    //   type: "dropdown",
    //   icon: <IoPersonCircleOutline />,
    //   hak: ["ASM"],
    //   menu: [
    //     {
    //       text: "Akun Saya",
    //       link: "/profil",
    //       hak: ["ASM"],
    //     },
    //     {
    //       text: "Ubah Akun",
    //       link: "/profil/ubah",
    //       hak: ["ASM"],
    //     },
    //     {
    //       text: "Ganti Password",
    //       link: "/profil/ganti-password",
    //       hak: ["ASM"],
    //     },
    //   ],
    // },
  ],

  ROUTES: [
    {
      exact: true,
      path: '/asm/master/grup-aset',
      page: GroupAset,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/entitas-aset',
      page: EntitasAset,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/kondisi-aset',
      page: KondisiAset,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/jenis-aset',
      page: JenisAset,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/kategori-aset',
      page: KategoriAset,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/pabrikan',
      page: Pabrikan,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-tanah',
      page: AsetTanah,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-tanah/:tipe',
      page: DataAsetTanah,
      hak: ['ASM', 'ASM_REG_TNH'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-tanah/:tipe/:id',
      page: DataAsetTanah,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-bangunan',
      page: AsetBangunan,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-bangunan/:tipe',
      page: DataAsetBangunan,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-bangunan/:tipe/:id',
      page: DataAsetBangunan,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-sistem',
      page: AsetSistem,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-sistem/:tipe',
      page: DataAsetSistem,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-sistem/:tipe/:id',
      page: DataAsetSistem,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-kendaraan',
      page: AsetKendaraan,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-kendaraan/:tipe',
      page: DataAsetKendaraan,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-kendaraan/:tipe/:id',
      page: DataAsetKendaraan,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-alat-dan-mesin',
      page: AsetMesinDanAlat,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-alat-dan-mesin/:tipe',
      page: DataAsetMesinDanAlat,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-alat-dan-mesin/:tipe/:id',
      page: DataAsetMesinDanAlat,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-inventaris-kantor',
      page: AsetInventarisKantor,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-inventaris-kantor/:tipe',
      page: DataAsetInventarisKantor,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-inventaris-kantor/:tipe/:id',
      page: DataAsetInventarisKantor,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-lain',
      page: AsetLain,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-lain/:tipe',
      page: DataAsetLain,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-lain/:tipe/:id',
      page: DataAsetLain,
      hak: ['ASM'],
    },

    // TRANSAKSI
    // Transfer Produksi Alat Mesin
    {
      exact: true,
      path: '/transaksi/transfer-produksi-alat-mesin',
      page: TransferProduksiAlatMesinList,
      hak: ['SUPA', 'ASM_TRN_TPA'],
    },
    {
      exact: true,
      path: '/transaksi/transfer-produksi-alat-mesin/list-permintaan-produksi',
      page: TransferProduksiAlatMesinPreform,
      hak: ['SUPA', 'ASM_TRN_TPA'],
    },
    {
      exact: true,
      path: '/transaksi/transfer-produksi-alat-mesin/tambah/:id_permintaan_produksi',
      page: TambahTransferAlatMesin,
      hak: ['SUPA', 'ASM_TRN_TPA'],
    },
    {
      exact: true,
      path: '/transaksi/transfer-produksi-alat-mesin/data',
      page: DataTransferProduksiAlatMesin,
      hak: ['SUPA', 'ASM_TRN_TPA'],
    },
    // Pemeliharaan Aset
    {
      exact: true,
      path: '/transaksi/pemeliharaan-aset',
      page: ListPemeliharaanAset,
      hak: ['SUPA', 'ASM_TRN_PA'],
    },

    // LAPORAN
    {
      exact: true,
      path: '/laporan/laporan-penyusutan-aset',
      page: LaporanPenyusutanAset,
      hak: ['SUPA', 'ASM_RPT_LPA'],
    },
  ],
};
