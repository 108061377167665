import { Services } from '../../../services';

class EntitasAsetApi {
  getPage(page, dataLength, key) {
    return Services.get(
      `/entitas_aset/page/?page=${page}&per_page=${dataLength}&q=${key}`
    );
  }
  single(value) {
    return Services.get(`/entitas_aset/single`, { params: { ...value } });
  }
  create(data) {
    return Services.post('/entitas_aset', data);
  }
  update(data) {
    return Services.put('/entitas_aset', data);
  }
  delete(value) {
    return Services.post('/entitas_aset/delete', value);
  }
  show(value) {
    return Services.put('/entitas_aset/show', value);
  }
  hide(value) {
    return Services.put('/entitas_aset/hide', value);
  }
}

export default new EntitasAsetApi();
