import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

const FilterButton = ({ active, className, onClick, loading }) => {
  return (
    <div>
      <Button
        size="md"
        value="primary"
        onClick={onClick}
        className={`ml-2 mb-2 px-3 text-nowrap ${className}`}
        disabled={loading ? true : false}
      >
        {loading ? (
          <div className="d-flex justify-content-center align-items-center py-1">
            <div className="d-flex align-items-center">
              <Spinner animation="border" variant="white" size="sm" />
            </div>
          </div>
        ) : (
          `FILTER: ${active ? 'ON' : 'OFF'}`
        )}
      </Button>
    </div>
  );
};

export default FilterButton;
