import { Services, UploadServices } from '../../../../services';

class AsetMesinDanAlatApi {
  getPage(page, dataLength, key) {
    return Services.get(
      `/asetmesinalat/page/?page=${page}&per_page=${dataLength}&q=${key}`
    );
  }
  getSingle(id) {
    return Services.get(`/asetmesinalat/single/?id_item_aset=${id}`);
  }
  getKode() {
    return Services.get('/asetmesinalat/no_baru?id_kategori_aset=29');
  }
  create(data) {
    return Services.post('/asetmesinalat', data);
  }
  update(data) {
    return Services.put('/asetmesinalat', data);
  }
  delete(id_grupaset) {
    return Services.post('/asetmesinalat/delete', id_grupaset);
  }
  show(id_grupaset) {
    return Services.put('/asetmesinalat/show', id_grupaset);
  }
  hide(id_grupaset) {
    return Services.put('/asetmesinalat/hide', id_grupaset);
  }
  getKategoriAset() {
    return Services.get('/asetmesinalat/kategori');
  }
  getPabrikan() {
    return Services.get('/pabrikan/dropdown');
  }
  getPengguna() {
    return Services.get('/unit_organisasi/dropdown');
  }
  getPenanggungJawab() {
    return Services.get('/karyawan/dropdown');
  }
  postPabrikan(value) {
    return Services.post('/pabrikan', value);
  }
  getGolongan() {
    return Services.get('/asetmesinalat/dropdown_golongan_aset');
  }
  getKondisi() {
    return Services.get('/asetmesinalat/dropdown_kondisi_aset');
  }
  getJenis() {
    return Services.get('/asetmesinalat/dropdown_jenis_aset');
  }
  uploadFileMultiple(data) {
    return UploadServices.post('/upload/aset_multiple', data);
  }

  uploadFile(data) {
    return Services.post('/asetmesinalat/gambar', data);
  }

  deleteFile(id) {
    return Services.post('/asetmesinalat/gambar_delete', id);
  }

  dropdown(value) {
    return Services.get('/asetmesinalat/dropdown', { params: { ...value } });
  }

  single(value) {
    return Services.get('/asetmesinalat/single', { params: { ...value } });
  }

  singleCheck(value) {
    return Services.get('/asetmesinalat/single_check', {
      params: { ...value },
    });
  }

  no_baru(value) {
    return Services.get('/asetmesinalat/no_baru', { params: { ...value } });
  }

  penyusutan(value) {
    return Services.get('/penyusutan_saldo_menurun/penyusutan', {
      params: { ...value },
    });
  }

  updatePenyusutan(value) {
    return Services.put('/asetmesinalat/penyusutan', value);
  }
  export() {
    return Services.get('/asetmesinalat/xlsx');
  }
}

export default new AsetMesinDanAlatApi();
