import { Services } from '../../../services';

class KondisiAsetApi {
  getPage(page, dataLength, key) {
    return Services.get(
      `/kondisiaset/page/?page=${page}&per_page=${dataLength}&q=${key}`
    );
  }
  create(data) {
    return Services.post('/kondisiaset', data);
  }
  update(data) {
    return Services.put('/kondisiaset', data);
  }
  delete(id_kondisi_aset) {
    return Services.post('/kondisiaset/delete', id_kondisi_aset);
  }
  show(id_kondisi_aset) {
    return Services.put('/kondisiaset/show', id_kondisi_aset);
  }
  hide(id_kondisi_aset) {
    return Services.put('/kondisiaset/hide', id_kondisi_aset);
  }
}

export default new KondisiAsetApi();
