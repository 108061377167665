import { createContext } from 'react';
import { useQuery } from 'react-query';
import { TransferProduksiAlatMesinApi } from 'api';

export const DropdownTransferAlatContext = createContext();

export const DropdownTransferAlatProvider = ({ children }) => {
  const STALE_DURATION_MINUTES = 4 * 60 * 1000; // 4 MINUTES
  const dropdownMenuKey = 'transfer_bahan_produksi';

  const dropdownStatusPermintaan = [
    { value: 'waiting', label: 'Waiting' },
    { value: 'on_progress', label: 'On Progress' },
    { value: 'done', label: 'Done' },
  ];

  const dropdownItem = useQuery(
    ['dropdown', 'item', 'job_order', dropdownMenuKey],
    () =>
      TransferProduksiAlatMesinApi.dropdown({ tipe: 'item' }).then((res) => {
        return (
          res.data.data.map((v) => ({
            value: v.id_item_buaso,
            label: `${v.kode_item} - ${v.nama_item}`,
          })) ?? []
        );
      }),
    { staleTime: STALE_DURATION_MINUTES }
  );

  const dropdownAlatMesin = useQuery(
    ['dropdown', 'item', 'alat_mesin', dropdownMenuKey],
    () =>
      TransferProduksiAlatMesinApi.dropdown({ tipe: 'alat_mesin' }).then(
        (res) => {
          return (
            res.data.data.map((v) => ({
              ...v,
              value: v.id_item_aset,
              label: v.nama_item_aset,
            })) ?? []
          );
        }
      ),
    { staleTime: STALE_DURATION_MINUTES }
  );

  const dropdownCustomer = useQuery(
    ['dropdown', 'customer', dropdownMenuKey],
    () =>
      TransferProduksiAlatMesinApi.dropdown({ tipe: 'customer' }).then((res) =>
        res.data.data.map((k) => ({
          value: k.id_customer,
          label: k.nama_customer,
        }))
      ),
    { staleTime: STALE_DURATION_MINUTES }
  );

  const dropdownProyek = useQuery(
    ['dropdown', 'proyek', dropdownMenuKey],
    () =>
      TransferProduksiAlatMesinApi.dropdown({ tipe: 'proyek' }).then((res) =>
        res.data.data.map((k) => ({
          value: k.id_proyek,
          label: k.nama_proyek,
        }))
      ),
    { staleTime: STALE_DURATION_MINUTES }
  );

  const dropdownKaryawan = useQuery(
    ['dropdown', 'karyawan', dropdownMenuKey],
    () =>
      TransferProduksiAlatMesinApi.dropdown({ tipe: 'karyawan' }).then((res) =>
        res.data.data.map((k) => ({
          value: k.id_karyawan,
          label: k.nama_karyawan,
        }))
      ),
    { staleTime: STALE_DURATION_MINUTES }
  );

  return (
    <DropdownTransferAlatContext.Provider
      value={{
        dropdownStatusPermintaan,
        dropdownItem,
        dropdownAlatMesin,
        dropdownCustomer,
        dropdownProyek,
        dropdownKaryawan,
      }}
    >
      {children}
    </DropdownTransferAlatContext.Provider>
  );
};

export const withDropdownTransferAlatProvider = (Component) => (props) => {
  return (
    <DropdownTransferAlatProvider>
      <Component {...props} />
    </DropdownTransferAlatProvider>
  );
};
