import { Fragment, useContext, useRef } from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import {
  ActionButton,
  DatePicker,
  InfoItemHorizontal,
  Input,
  InputCurrency,
  SelectSearch,
  TextArea,
} from 'components';
import {
  DropdownTransferAlatContext,
  TransferAlatMesinContext,
} from '../Context';

export const ModalFormTransfer = ({ generateMockNumber }) => {
  const { dropdownAlatMesin, dropdownKaryawan } = useContext(
    DropdownTransferAlatContext
  );
  const { modalForm, setModalForm, setDataTransfer } = useContext(
    TransferAlatMesinContext
  );
  const { data, index } = modalForm;
  const isUpdate = modalForm.action === 'update';
  const formikRef = useRef(null);

  const initialValues = {
    id_permintaan_produksi: data?.id_permintaan_produksi ?? '',
    tgl_transfer_produksi_alat_mesin:
      data?.tgl_transfer_produksi_alat_mesin ?? '',
    no_transfer_produksi_alat_mesin:
      data?.no_transfer_produksi_alat_mesin ?? '',
    id_item_aset: data?.id_item_aset ?? '',
    nama_item_aset: data?.nama_item_aset ?? '',
    nama_satuan_pakai: data?.nama_satuan_pakai ?? '',
    qty_transfer_produksi_alat_mesin:
      data?.qty_transfer_produksi_alat_mesin ?? '0',
    qty_permintaan: data?.qty ?? data?.qty_permintaan ?? 0,
    id_karyawan: data?.id_karyawan ?? '',
    nama_karyawan: data?.nama_karyawan ?? '',
    keterangan: data?.keterangan ?? '',
  };

  const validationSchema = yup.object().shape({
    tgl_transfer_produksi_alat_mesin: yup
      .date()
      .required('Tgl. Transfer Bahan Produksi diperlukan!'),
    id_item_aset: yup.string().required('Pilih Salah Satu Item'),
    id_karyawan: yup.string().required('Pilih Karyawan'),
    qty_transfer_produksi_alat_mesin: yup
      .string()
      .required('Qty. Transfer diperlukan!'),
  });

  const onSubmit = (values, { resetForm }) => {
    isUpdate
      ? setDataTransfer((prev) => prev.toSpliced(index, 1, values))
      : setDataTransfer((prev) => [...prev, values]);

    resetForm();
    setModalForm({
      show: false,
      data: null,
    });
  };

  const closeModal = () => setModalForm({ show: false, data: null });

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        setFieldValue,
        setValues,
        errors,
        touched,
        handleSubmit,
      }) => (
        <Modal show={modalForm.show} size="md" onHide={closeModal}>
          <Modal.Header closeButton className="text-capitalize">
            Tambah Data Transfer Alat Mesin Produksi <br /> (Machine Process)
          </Modal.Header>
          <Modal.Body>
            <InfoSection data={values} />

            <Row>
              <Col>
                <DatePicker
                  label="Tgl. Transfer Alat Mesin Produksi"
                  dateFormat="dd/MM/yyyy"
                  onChange={async (date) => {
                    setFieldValue('tgl_transfer_produksi_alat_mesin', date);

                    const mockNumberResult = await generateMockNumber(date);
                    setFieldValue(
                      'no_transfer_produksi_alat_mesin',
                      mockNumberResult
                    );
                  }}
                  selected={
                    values?.tgl_transfer_produksi_alat_mesin
                      ? new Date(values?.tgl_transfer_produksi_alat_mesin)
                      : null
                  }
                  error={
                    errors.tgl_transfer_produksi_alat_mesin &&
                    touched.tgl_transfer_produksi_alat_mesin
                  }
                  errorText={errors.tgl_transfer_produksi_alat_mesin}
                />
              </Col>

              <Col>
                <Input
                  readOnly
                  label="No. Transfer Alat Mesin Produksi"
                  value={values.no_transfer_produksi_alat_mesin}
                />
              </Col>
            </Row>

            <SelectSearch
              loading={dropdownAlatMesin?.isLoading}
              label="Item Alat Mesin"
              placeholder="Pilih salah satu . . ."
              option={dropdownAlatMesin?.data ?? []}
              defaultValue={dropdownAlatMesin?.data?.find(
                (item) => item.value === values.id_item_aset
              )}
              onChange={(val) =>
                setValues({
                  ...values,
                  id_item_aset: val.value,
                  nama_item_aset: val.label,
                  nama_satuan_pakai: val.nama_satuan_pakai,
                })
              }
              error={errors.id_item_aset && touched.id_item_aset}
              errorText={errors.id_item_aset}
            />

            <Row>
              <Col>
                <InputCurrency
                  prefix=""
                  label="Qty. Transfer"
                  className="text-right"
                  defaultValue={values.qty_transfer_produksi_alat_mesin}
                  onChange={(val) =>
                    setFieldValue('qty_transfer_produksi_alat_mesin', val)
                  }
                  error={
                    errors.qty_transfer_produksi_alat_mesin &&
                    touched.qty_transfer_produksi_alat_mesin
                  }
                  errorText={errors.qty_transfer_produksi_alat_mesin}
                />
              </Col>

              <Col>
                <Input
                  readOnly
                  label="Satuan Transfer"
                  defaultValue={values.nama_satuan_pakai}
                  value={values.nama_satuan_pakai}
                  onChange={(val) => setFieldValue('nama_satuan_pakai', val)}
                  error={errors.nama_satuan_pakai && touched.nama_satuan_pakai}
                  errorText={errors.nama_satuan_pakai}
                />
              </Col>
            </Row>

            <SelectSearch
              label="Diserahkan Kepada"
              option={dropdownKaryawan?.data}
              loading={dropdownKaryawan?.isLoading}
              placeholder="Pilih salah satu . . ."
              defaultValue={
                values?.id_karyawan
                  ? dropdownKaryawan?.data?.find(
                      (k) => k.value === values.id_karyawan
                    )
                  : null
              }
              onChange={(val) =>
                setValues({
                  ...values,
                  id_karyawan: val.value,
                  nama_karyawan: val.label,
                })
              }
              error={errors.id_karyawan && touched.id_karyawan}
              errorText={errors.id_karyawan}
            />

            <TextArea
              label="Keterangan"
              rows={3}
              value={values.keterangan}
              onChange={(val) => setFieldValue('keterangan', val.target.value)}
              error={errors.keterangan && touched.keterangan}
              errorText={errors.keterangan}
            />
          </Modal.Body>

          <Modal.Footer>
            <ActionButton
              className="btn btn-light"
              text="Batal"
              onClick={closeModal}
            />
            <ActionButton text="Simpan" onClick={handleSubmit} />
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
};

const InfoSection = ({ data }) => {
  return (
    <Fragment>
      <InfoItemHorizontal label="Item Alat Mesin" text={data?.nama_item_aset} />
      <InfoItemHorizontal
        label="Qty. Permintaan"
        text={`${parseFloat(data.qty_permintaan)} ${
          data.nama_satuan_pakai ?? 'Batang'
        }`}
      />
      <hr className="my-2 " />
    </Fragment>
  );
};
