import { createContext, useState } from 'react';

export const TransferAlatMesinContext = createContext();

export const TransferAlatMesinProvider = ({ children }) => {
  const [dataJobOrder, setJobOrder] = useState({});
  const [dataPermintaan, setDataPermintaan] = useState([]);
  const [dataTransfer, setDataTransfer] = useState([]);
  const [dataHistory, setDataHistory] = useState([]);
  const [modalForm, setModalForm] = useState({
    show: false,
    action: 'create',
    index: null,
    data: null,
  });
  const [modalKonfirmasi, setModalKonfirmasi] = useState(false);

  return (
    <TransferAlatMesinContext.Provider
      value={{
        dataJobOrder,
        setJobOrder,
        dataPermintaan,
        setDataPermintaan,
        dataTransfer,
        setDataTransfer,
        dataHistory,
        setDataHistory,
        modalForm,
        setModalForm,
        modalKonfirmasi,
        setModalKonfirmasi,
      }}
    >
      {children}
    </TransferAlatMesinContext.Provider>
  );
};

export const withTransferAlatMesinProvider = (Component) => (props) => {
  return (
    <TransferAlatMesinProvider>
      <Component {...props} />
    </TransferAlatMesinProvider>
  );
};
