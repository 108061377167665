import { Services } from 'services';

class TransferProduksiAlatMesinApi {
  page(params) {
    return Services.get('/transfer_produksi_alat_mesin/page', {
      params,
    });
  }

  preform(params) {
    return Services.get('/transfer_produksi_alat_mesin/permintaan_page', {
      params,
    });
  }

  single(params) {
    return Services.get('/transfer_produksi_alat_mesin/single_permintaan', {
      params,
    });
  }

  updateStatusPermintaan(data) {
    return Services.post(
      '/transfer_produksi_alat_mesin/status_permintaan',
      data
    );
  }

  transfer(params) {
    return Services.get('/transfer_produksi_alat_mesin/transfer', {
      params,
    });
  }

  dropdown(params) {
    return Services.get('/transfer_produksi_alat_mesin/dropdown', {
      params,
    });
  }

  no_baru(params) {
    return Services.get('/transfer_produksi_alat_mesin/no_baru', {
      params,
    });
  }

  create(value) {
    return Services.post('/transfer_produksi_alat_mesin', value);
  }

  delete(value) {
    return Services.post('/transfer_produksi_alat_mesin/delete', value);
  }
}

export default new TransferProduksiAlatMesinApi();
