import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoCloudDownloadOutline } from 'react-icons/io5';

const DownloadButton = ({ tooltipText, tooltipPlacement, onClick }) => {
  return (
    <OverlayTrigger
      placement={tooltipPlacement}
      overlay={<Tooltip>{tooltipText ? tooltipText : 'Unduh data'}</Tooltip>}
    >
      <Button variant="success" size="sm" className="m-1" onClick={onClick}>
        <IoCloudDownloadOutline />
      </Button>
    </OverlayTrigger>
  );
};

export default DownloadButton;
