import { useState } from 'react';

export const ItemAlatMesinCollapse = ({ data = [] }) => {
  const [isReadMore, setReadMore] = useState(false);

  const splicedData = [...data].splice(0, 2);
  const results = isReadMore ? data : splicedData;

  return (
    <>
      <ul className="pl-3 mb-1" style={{ fontSize: '12px' }}>
        {results?.map((value, index) => (
          <li key={index} index={index}>
            {`${value.nama_item} ${parseFloat(value.qty_convert) ?? ''} ${
              value.nama_satuan ?? ''
            }` ?? '-'}
          </li>
        ))}
      </ul>

      {data.length > 2 && (
        <u
          className="text-primary"
          style={{
            cursor: 'pointer',
          }}
          onClick={() => setReadMore((prev) => !prev)}
        >
          {isReadMore ? 'Sembunyikan' : 'Selengkapnya...'}
        </u>
      )}
    </>
  );
};
