import { useContext } from 'react';
import { IoAddOutline } from 'react-icons/io5';
import {
  Table,
  THead,
  TBody,
  Th,
  ThFixed,
  Td,
  TdFixed,
  Tr,
  SelectSearch,
  ActionButton,
  DataStatus,
} from 'components';
import {
  DropdownTransferAlatContext,
  TransferAlatMesinContext,
} from '../Context';
import { RupiahConvert } from 'utilities';
import { calcSubTotal, calcGrandTotal } from '../Helpers';

export const TablePermintaanMP = ({ data = [{}, {}] }) => {
  const { setModalForm, setDataPermintaan } = useContext(
    TransferAlatMesinContext
  );
  const { dropdownStatusPermintaan } = useContext(DropdownTransferAlatContext);

  const onChangeStatusPermintaan = ({ value, index }) =>
    setDataPermintaan((prv) =>
      prv.toSpliced(index, 1, { ...prv[index], status_permintaan: value })
    );

  return (
    <Table>
      <THead>
        <Tr className="text-center">
          <ThFixed>No.</ThFixed>
          <Th width={300}>Item Alat Mesin</Th>
          <Th>Qty</Th>
          <Th>Satuan</Th>
          <Th width={200}>Unit Price (Rp)</Th>
          <Th>Konst.</Th>
          <Th width={200}>Sub Total Price (Rp)</Th>
          <Th width={150}>Status Permintaan</Th>
          <ThFixed>Aksi</ThFixed>
        </Tr>
      </THead>

      <TBody>
        {data?.length ? (
          data.map((val, index) => (
            <Tr key={index}>
              <TdFixed className="text-center">{index + 1}</TdFixed>
              <Td>{val.nama_item_aset}</Td>
              <TdFixed textRight>{parseFloat(val.qty)}</TdFixed>
              <TdFixed>{val.nama_satuan_pakai}</TdFixed>
              <TdFixed textRight>
                {RupiahConvert(String(Math.round(val.unit_price))).detail}
              </TdFixed>
              <TdFixed textRight>{parseFloat(val.konstanta)}</TdFixed>
              <TdFixed textRight>
                {RupiahConvert(String(calcSubTotal(val))).detail}
              </TdFixed>
              <td className="p-0 align-self-center">
                <SelectSearch
                  option={dropdownStatusPermintaan}
                  defaultValue={dropdownStatusPermintaan?.find(
                    ({ value }) => value === val.status_permintaan
                  )}
                  onChange={({ value }) =>
                    onChangeStatusPermintaan({ value, index })
                  }
                />
              </td>
              <Td>
                <div className="d-flex justify-content-center align-items-center">
                  <ActionButton
                    size="sm"
                    className="m-1"
                    onClick={() =>
                      setModalForm({
                        show: true,
                        data: val,
                      })
                    }
                  >
                    <IoAddOutline />
                  </ActionButton>
                </div>
              </Td>
            </Tr>
          ))
        ) : (
          <Tr>
            <Td colSpan={9}>
              <DataStatus text="Tidak ada data" />
            </Td>
          </Tr>
        )}
        {Boolean(data?.length) && (
          <tr className="font-weight-bold">
            <Td textRight colSpan={6}>
              Total Price
            </Td>
            <Td textRight>
              {RupiahConvert(String(calcGrandTotal(data ?? []))).detail}
            </Td>
            <Td colSpan={2} />
          </tr>
        )}
      </TBody>
    </Table>
  );
};
